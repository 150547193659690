import { Helmet } from "react-helmet";
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import emailjs from 'emailjs-com'
import React, { useRef } from 'react';

import locdata from "../../data/locdata.json";
import DelhiPopularcity from "./nearme/Delhinearme";


const initialState = {
    from_name: '',
    mobile: '',
    adress: '',
}
export const Delhi = ({ title, number }) => {
    const articleSchema = {
        "@context": "https://schema.org",
        "@type": "Article",
        "headline": "RO Service Center in West Bengal",
        "author": {
            "@type": "Person",
            "name": "Wappotix"
        },
        "datePublished": "2024-09-03",
        "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "http://www.aquagroservices.co.in/"
        },
        "articleBody": "Best ro repair and maintanance service in West bengal"
    };



    const canonicalUrl = `https://aquagroservices.co.in/${title}`;

    const [{ from_name, mobile, adress }, setState] = useState(initialState)
    const formRef = useRef();
    const navigate = useNavigate();
    const handleChange = (e) => {
        const { name, value } = e.target
        setState((prevState) => ({ ...prevState, [name]: value }))
    }
    const validateMobile = (mobile) => {
        const regex = /^\d{10}$/; // Regex for 10 digit mobile number
        return regex.test(mobile);
      };
    const clearState = () => setState({ ...initialState })

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!validateMobile(mobile)) {
      alert("Please enter a valid 10-digit mobile number.");
      return;
    }
        console.log(from_name, mobile, adress)
        emailjs
            .sendForm(
                'service_156qo9u', 'template_rli3glb', e.target, 'DOFeyEAm_0FtUzs8g'
            )
            .then(
                (result) => {
                    alert('Your Call Back Request has been submitted , you will get a get from our team within 5-10 min. Thanks for your patience')
                    clearState();
                    formRef.current.reset();
                    navigate("/thanks");
                },
                (error) => {
                    console.log(error.text)
                }
            )
    }
    return (
        <>
            <div id="differntlocation">
                <Helmet>
                    <link rel="canonical" href={canonicalUrl} />
                    <title>AQUA G RO SERVICES {title} @{number} | Water Purifier Service</title>
                    <meta name="keywords" content={`ro service, water purifier service in ${title}, ro repair, ro service near me, water purifier service near me, ro service center, ro service center near me, ro service centre, ro service centre in Bihar`} />
                    <meta name="keywords" content={`ro service in ${title}`} />
                    <meta name="keywords" content={`Water purifier service in ${title}`} />
                    <meta name="keywords" content={`ro repair in ${title}`} />
                    <meta name="author" content="AQUA G RO SERVICES is best ro repair and maintanancce service in india" />
                    <meta name="description" content={`RO Service {title}: We are India's leading RO water purifier repair and maintanance service provider of all brands like Kent, Pureit, Livpure, Aqua Guard, Aqua Fresh etc. RO repair service in ${title}`} ></meta>
                </Helmet>
                <script type="application/ld+json">
                    {JSON.stringify(articleSchema)}
                </script>
                <div className="navigation-wrapper">
                    <div className="Container fluid ">
                        <header className="location-header" >
                            <div className="container h-100">
                                <div className="row hdrcontainer">
                                    <div className="col-md-8">
                                        <div className="text-center align-items-center">
                                            <div className="w-100 text-onbanner">
                                                <h1 className="display-3"> Water Purifier Service In {title} @8130112493</h1>
                                            </div>
                                            <div className="call-action-area">
                                                <span className="number"><a href="tel:8130112493 " style={{ fontWeight: 'bold' }}><i className="fa fa-phone"></i>  +91-8130112493 </a></span>
                                                <span className="number"><a href="https://api.whatsapp.com/send?phone=918130112493" target="_blank" style={{ fontWeight: 'bold' }}><i className="fa fa-whatsapp" style={{ backgroundColor: '#25D366' }}></i>  +91-8130112493 </a></span>
                                                {/*<span className="request"><a data-toggle="modal" data-target="#newModalForWizard">Request a Call Back</a></span> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 locform">
                                        <div className='row'>
                                            <div className='section-title'>
                                                <h2>SUBMIT A CALL BACK REQUEST</h2>
                                                <p>
                                                    Please fill out the form below to send us an email and we will
                                                    get back to you as soon as possible.
                                                </p>
                                            </div>
                                            <form ref={formRef} name='sentMessage' onSubmit={handleSubmit}>
                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <div className='form-group'>
                                                            <input
                                                                type='text'
                                                                id='name'
                                                                name='from_name'
                                                                className='form-control'
                                                                placeholder='Full Name*'
                                                                required
                                                                onChange={handleChange}
                                                            />
                                                            <p className='help-block text-danger'></p>
                                                            <input
                                                                type='number'
                                                                id='mobile'
                                                                name='mobile'
                                                                className='form-control'
                                                                placeholder='Mobile Number*'
                                                                required
                                                                onChange={handleChange}
                                                                maxLength={10}
                                                                minLength={10}

                                                            />
                                                            <p className='help-block text-danger'></p>
                                                            <div className='form-group'>
                                                                <textarea
                                                                    name='adress'
                                                                    id='adress'
                                                                    className='form-control'
                                                                    rows='3'
                                                                    placeholder='Address'

                                                                    onChange={handleChange}
                                                                ></textarea>
                                                                <p className='help-block text-danger'></p>
                                                            </div>
                                                            <div id='success'></div>

                                                        </div>
                                                    </div>


                                                </div>

                                                <div id='success'></div>
                                                <button type='submit' className='btn btn-custom btn-lg' style={{ marginLeft: '20px' }}>
                                                    Get a Call Back From Us
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>

                        <section id="Aqufresh-content-area ">
                            <div className="container">
                                <div className="row row-reverse">

                                    <div className="common-section brandss-section-here" >
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="content-area-">
                                                    <h2><strong>RO Water Purifier Service in {title}</strong></h2>

                                                    <p>When it comes to ensuring the safety and purity of your drinking water, choosing the right <a href="/ro-service-Delhi"><strong>RO service center in {title} </strong></a>&nbsp;  is crucial. Delhi faces significant water quality challenges, making the need for a reliable water purification system even more important. Here’s why an &nbsp;<a href="/"><strong>RO service center </strong></a>&nbsp; in Delhi is essential for maintaining your health and your water purifier’s efficiency.</p>

                                                    <p>Choosing an  &nbsp;<a href="/ro-service-Delhi"><strong>RO service center in Delhi</strong></a>&nbsp;means securing the long-term functionality and efficiency of your water purifier. With expert technicians, a full range of services, and a commitment to quality, you can rely on a professional service center to meet all your water purification needs. Whether you need installation, repair, or routine maintenance, a trusted &nbsp;<a href="/"><strong>RO service center </strong></a>&nbsp;helps ensure that you always have access to pure, healthy drinking water.</p>

                                                    <p>For the best service and peace of mind, make sure to choose a reputable <a href="/"><strong>RO service center </strong></a>&nbsp; in Delhi today.</p>
                                                    
                                                    <p><strong>RO Service | RO Service Center Number&nbsp;{number}</strong></p>

                                                    <div className="table-responsive">
                                                        <table className="table table-striped table-responsive">
                                                            <thead>
                                                                <tr className="table-success">
                                                                    <td><strong>#</strong></td>
                                                                    <td><strong>RO Water Purifier Service</strong></td>
                                                                    <td><strong>Contact Number</strong></td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr className="table-success">
                                                                    <td><strong>1</strong></td>
                                                                    <td>RO Service&nbsp;{title}</td>
                                                                    <td><a href="tel:{number}">8130112493</a></td>
                                                                </tr>
                                                                <tr className="table-primary">
                                                                    <td><strong>2</strong></td>
                                                                    <td>Water Purifier Service&nbsp;{title}</td>
                                                                    <td><a href="tel:8130112493">8130112493</a></td>
                                                                </tr>
                                                                <tr className="table-success">
                                                                    <td><strong>3</strong></td>
                                                                    <td>Water Purifier Service Near Me&nbsp;{title}</td>
                                                                    <td><a href="tel:8130112493">8130112493</a></td>
                                                                </tr>
                                                                <tr className="table-primary">
                                                                    <td><strong>4</strong></td>
                                                                    <td>RO Service Near Me in&nbsp;{title}</td>
                                                                    <td><a href="tel:8130112493">8130112493</a></td>
                                                                </tr>
                                                                <tr className="table-success">
                                                                    <td><strong>5</strong></td>
                                                                    <td>RO Repair Service&nbsp;{title}</td>
                                                                    <td><a href="tel:8130112493">8130112493</a></td>
                                                                </tr>
                                                                <tr className="table-primary">
                                                                    <td><strong>6</strong></td>
                                                                    <td>RO Installation service in&nbsp;{title}</td>
                                                                    <td><a href="tel:8130112493">8130112493</a></td>
                                                                </tr>
                                                                <tr className="table-success">
                                                                    <td><strong>7</strong></td>
                                                                    <td>RO Service Toll Free Number in&nbsp;{title}</td>
                                                                    <td><a href="tel:8130112493">8130112493</a></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <h3><strong>Why Choose RO Service in {title}?</strong></h3>

                                                    <p>In a city like {title}, where water quality can be a major concern, ensuring access to clean and safe drinking water is more important than ever. That’s where &nbsp;<a href="/ro-service-Delhi"><strong>RO services in Delhi</strong></a>&nbsp;comes in. Whether you are looking for installation, repair, or routine maintenance for your water purifier, opting for professional RO services in Delhi is essential to keep your purifier in optimal condition and your water pure.</p>

                                                    <p>In a city where water quality is a significant concern, opting for RO service in Delhi is a wise investment in your health, the longevity of your purifier, and the overall quality of your drinking water. Whether you need installation, repairs, maintenance, or part replacements, reliable <a href="/ro-service-Delhi"><strong>RO services in Delhi</strong></a>&nbsp; ensure that you have access to safe, clean water at all times.</p>

                                                    <p>For expert, timely, and affordable <a href="/ro-service-Delhi"><strong>RO services in Delhi</strong></a>&nbsp;, trust local professionals to handle all your water purification needs. Don’t wait until your purifier breaks down—schedule a regular maintenance check and keep your system in top condition</p>

                                                    <p><strong>RO Service Cost In {title}</strong></p>

                                                    <div className="table-responsive">
                                                        <table className="table table-striped table-responsive">
                                                            <tbody>
                                                                <tr className="table-success">
                                                                    <td>
                                                                        <p><strong>RO Service Type</strong></p>
                                                                    </td>
                                                                    <td>
                                                                        <p><strong>Water Purifier Service Cost</strong></p>
                                                                    </td>
                                                                </tr>
                                                                <tr className="table-primary">
                                                                    <td>
                                                                        <p>RO service in {title}</p>
                                                                    </td>
                                                                    <td>
                                                                        <p>299/- (Additional Charge For Spare Parts)</p>
                                                                    </td>
                                                                </tr>
                                                                <tr className="table-success">
                                                                    <td>
                                                                        <p>Water Purifier Installation in {title}</p>
                                                                    </td>
                                                                    <td>
                                                                        <p>499/-</p>
                                                                    </td>
                                                                </tr>
                                                                <tr className="table-primary">
                                                                    <td>
                                                                        <p>Un-installation &amp; Installation of RO Service in {title}</p>
                                                                    </td>
                                                                    <td>
                                                                        <p>699/-</p>
                                                                    </td>
                                                                </tr>
                                                                <tr className="table-success">
                                                                    <td>
                                                                        <p>Installation &amp; & Water Purifier Service in {title}</p>
                                                                    </td>
                                                                    <td>
                                                                        <p>699/-</p>
                                                                    </td>
                                                                </tr>
                                                                <tr className="table-primary">
                                                                    <td>
                                                                        <p>Visiting Charges for RO Service in {title}</p>
                                                                    </td>
                                                                    <td>
                                                                        <p>299/- (If No Service)</p>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <h2></h2><h2><strong>Why Aqua G RO Services is best in {title}?</strong></h2>
                                            
                                                    <h3><strong>Services Provided By Water Purifier Service Centre In {title}</strong></h3>

                                                    <p><strong><a href="/">Aqua G RO Purifier services</a>&nbsp;</strong>in {title} offers a wide range of services to ensure your water purifier functions effectively, providing you with clean and safe drinking water. Here’s a list of key services provided:</p>

                                                    <ul>
                                                        <li><b><u>1. RO Installation:</u></b> Professional installation of your water purifier ensures it is set up correctly for optimal performance. Technicians evaluate your water quality and recommend the best purifier model for your needs.</li>
                                                        <li><b><u>2. RO Repair and Troubleshooting:</u></b> If your water purifier is malfunctioning, a service centre can quickly diagnose the problem and provide timely repairs. Common issues like low water flow, strange noises, or no water output are resolved to restore the system’s functionality.</li>
                                                        <li><b><u>3. Annual Maintenance Contracts (AMC):</u></b> AMCs offer regular servicing of your water purifier to prevent future breakdowns. The service centre ensures timely maintenance, including filter changes, cleaning, and checks, to extend the life of your purifier and maintain its efficiency.</li>
                                                        <li><b><u>4. Filter and Membrane Replacement:</u></b> Over time, filters and membranes lose their effectiveness. Service centres provide timely replacement of these essential components to ensure your purifier continues to remove impurities and provide clean water.</li>
                                                        <li><b><u>5. Water Quality Testing:</u></b> A water purifier service centre can assess the quality of your water and recommend the right treatment solution. Regular testing ensures that the purifier is effectively removing contaminants, keeping your water safe and healthy.</li>
                                                        <li><b><u>6. Cleaning and Sanitization:</u></b> Periodic cleaning and sanitization of the RO system help prevent bacterial buildup and ensure the purifier operates efficiently. Service centres provide deep cleaning to ensure water purity is not compromised.</li>
                                                        <li><b><u>7. RO Upgrade and Replacements:</u></b> If your current RO system is outdated or no longer effective, the service centre can help with upgrades to more advanced models or complete replacements. They provide solutions tailored to your water purification needs.</li>
                                                        <li><b><u>8. Technical Support and Consultation:</u></b> For any queries or concerns regarding your water purifier, a professional service centre offers technical support. They provide advice on maintenance, usage, and troubleshooting, ensuring your system runs smoothly.</li>
                                                        <li><b><u>9. Emergency Repairs:</u></b> In case of sudden breakdowns, emergency repair services are available to fix issues and restore your purifier’s functionality as quickly as possible, minimizing downtime.</li>
                                                        <li><b><u>10. Water Purifier Servicing for All Brands:</u></b> Most service centres cater to a wide range of RO water purifier brands. Whether you own a Kent, Aquaguard, Whirlpool, or any other brand, a service centre has expertise in handling different systems.</li>
                                                        <blockquote>
                                                            <p>By choosing a reliable water purifier <a href="/ro-service-Delhi"><strong>RO services in Delhi</strong></a>&nbsp;, you ensure that your purifier remains in top condition, providing you with safe and healthy drinking water consistently.</p>
                                                        </blockquote>
                                                    </ul>

                                                    <div id='collapseprovide' className='text-center'>
                                                        <div className='container'>
                                                            <div className='section-title'>
                                                                <h2 style={{ textAlign: 'center' }}>We Provide</h2>
                                                            </div>
                                                            <div className='row'>
                                                                <div id="accordion" className="card ">

                                                                    <div className="card-header col-xs-6 col-md-3" id="headingRepair">
                                                                        <a className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseRepair" aria-expanded="true" aria-controls="collapseRepair">
                                                                            <i className='fa fa-tools'></i>
                                                                            <h3>REPAIR</h3>
                                                                        </a>
                                                                    </div>
                                                                    <div className="card-header col-xs-6 col-md-3" id="headingService">
                                                                        <a className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseService" aria-expanded="false" aria-controls="collapseService">
                                                                            <i className='fa fa-gear'></i>
                                                                            <h3>SERVICE</h3>
                                                                        </a>
                                                                    </div>
                                                                    <div className="card-header col-xs-6 col-md-3" id="headingInstallation">
                                                                        <a className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseInstallation" aria-expanded="false" aria-controls="collapseInstallation">
                                                                            <i className='fa fa-archive'></i>
                                                                            <h3>INSTALLATION</h3>
                                                                        </a>
                                                                    </div>
                                                                    <div className="card-header col-xs-6 col-md-3" id="headinUninstallation">
                                                                        <a className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseUninstallation" aria-expanded="true" aria-controls="collapseUninstallation">
                                                                            <i className='fa fa-minus-circle'></i>
                                                                            <h3>UNINSTALLATION</h3>
                                                                        </a>
                                                                    </div>
                                                                    <div className="colapsebox">
                                                                        <div id="collapseRepair" className="collapse" aria-labelledby="headingRepair" data-parent="#accordion">
                                                                            <div className="card-body">
                                                                                <h3>Inspection of the RO Plant</h3>
                                                                                <ol>
                                                                                    <li>•	You switch off the plant and disconnect the power and water connections.</li>
                                                                                    <li>•	Slowly disassemble all the components of the ROplant and clean them.</li>
                                                                                    <li>•	Check the filter, membrane, pump, valve, tank, tube, and fitting of the plant.</li>
                                                                                    <li>•	For any leakage, rust, crack, or defect, if any problems occur, fix it or replace it.</li>
                                                                                    <li>•	Reconnect the restart the RO plant.</li>
                                                                                    <li>•	You check the performance of the plant, water pressure, TDS of water, pH of water, and taste of water.</li>
                                                                                    <li>•	After checking the plant, generate a report and give it to the customer.</li>
                                                                                </ol>
                                                                            </div>
                                                                        </div>
                                                                        <div id="collapseService" className="collapse" aria-labelledby="headingService" data-parent="#accordion">
                                                                            <div className="card-body">
                                                                                <h3>RO service means reverse osmosis service, which is a research process of its own and the importance of the service is because.</h3>
                                                                                <ol>
                                                                                    <li>•	Through RO service bacteria, viruses, metals, and unwanted elements present in the water are removed which makes the water healthy and pure.</li>
                                                                                    <li>•	The RO service provides water TDS level is low which improves water quality.</li>
                                                                                    <li>•	With the RO service, the pH of the water is balanced which makes the water neither too acidic nor too salty.</li>
                                                                                    <li>•	With the service, the taste and consistency of the water are better which makes the water drinkable.</li>
                                                                                    <li>•	With the service, you take care of the plant, and long-lasting capability increases which makes the RO more economical and environmental.</li>
                                                                                </ol>
                                                                            </div>
                                                                        </div>
                                                                        <div id="collapseInstallation" className="collapse" aria-labelledby="headingInstallation" data-parent="#accordion">
                                                                            <div className="card-body">
                                                                                <h3>Installation of the RO plant can be done in the following steps:</h3>
                                                                                <ol>
                                                                                    <li>•	Once the RO plant is fixed in a moist and dry place where drainage and electric connection are available.</li>
                                                                                    <li>•	You should read the manual given along with the RO and connect all the components properly.</li>
                                                                                    <li>•	You connect the RO plant to the water source and open the valve.</li>
                                                                                    <li>•	You connect the power source and switch on.</li>
                                                                                    <li>•	With the service, you take care of the plant, and long-lasting capability increases which makes the RO more economical and environmental.</li>
                                                                                    <li>•	You let the RO plant run for a few minutes and take a sample of the pure water.</li>
                                                                                    <li>•	You check the performance of the plant and in case of any problem or leakage stop it immediately and fix it.</li>
                                                                                </ol>
                                                                            </div>
                                                                        </div>
                                                                        <div id="collapseUninstallation" className="collapse" aria-labelledby="headingUninstallation" data-parent="#accordion">
                                                                            <div className="card-body">
                                                                                <h3>Uninstallation of the RO plant can be done in the following steps:</h3>
                                                                                <ol>
                                                                                    <li>•	Disconnect the RO plant from the power source and turn off the switch.</li>
                                                                                    <li>•	Disconnect the RO plant from the water source and close the valve.</li>
                                                                                    <li>•	You can slowly disassemble and clean all components of the RO plant.</li>
                                                                                    <li>•	Pack the RO plant securely and transport or store it elsewhere.</li>
                                                                                </ol>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <p><strong>How Water Purifier Service Near me Process Work?</strong></p>

                                                    <div className="table-responsive">
                                                        <table className="table table-striped table-responsive">
                                                            <tbody>
                                                                <tr className="table-success">
                                                                    <td>
                                                                        <p>1. Book RO Service Near Me by calling us/filling the Enquiry form.</p>
                                                                    </td>
                                                                </tr>
                                                                <tr className="table-primary">
                                                                    <td>
                                                                        <p>2. Get technician confirmation call.</p>
                                                                    </td>
                                                                </tr>
                                                                <tr className="table-success">
                                                                    <td>
                                                                        <p>3. Schedule your service.</p>
                                                                    </td>
                                                                </tr>
                                                                <tr className="table-primary">
                                                                    <td>
                                                                        <p>4. Technician will visit at your place.</p>
                                                                    </td>
                                                                </tr>
                                                                <tr className="table-success">
                                                                    <td>
                                                                        <p>5. Service done.</p>
                                                                    </td>
                                                                </tr>
                                                                <tr className="table-primary">
                                                                    <td>
                                                                        <p>6. Make payment and share your valuable feedback.</p>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>


                                                    <div className="tz">
                                                        <div className="tz-2 top-ms">
                                                            <div className="tz-2-com tz-2-main tz1">
                                                                <h4>How it Works </h4>
                                                                <div className="steps-of-work">
                                                                    <div className="step">
                                                                        <div className="heading a">Step 1</div>
                                                                        <div className="content">
                                                                            <img src="img/location/cont.png" alt="Book Service" />
                                                                            <p>Contact Us to Book Service</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="step">
                                                                        <div className="heading b">Step 2</div>
                                                                        <div className="content">
                                                                            <img src="img/location/sche.png" alt="Get Technician Confirmation" />
                                                                            <p>Get Technician Confirmation </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="step">
                                                                        <div className="heading c">Step 3</div>
                                                                        <div className="content">
                                                                            <img src="img/location/vis.png" alt="Technician will Visit &amp; Complete Work" />
                                                                            <p>Technician will Visit &amp; Complete Work</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="step">
                                                                        <div className="heading d">Step 4</div>
                                                                        <div className="content">
                                                                            <img src="img/location/wal.png" alt="Pay after Service Done" />
                                                                            <p>Pay after Service Done </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            

                                                            <div className="tz-2-com tz-2-main tz4">
                                                                <h4> Frequently Asked Questions</h4>
                                                                <div className="col-md-12 main4">
                                                                    <div className="demo">
                                                                        <div>

                                                                            <div className="panel-group" id="accordion">
                                                                                <div className="card panel panel-default">
                                                                                    <div className="card-header" id="headingOne">
                                                                                        <h5 className="mb-0">
                                                                                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                                                <i className="more-less glyphicon glyphicon-plus">&nbsp;</i> Aqua RO Service Center Toll Free No in {title} ?
                                                                                            </button>
                                                                                        </h5>
                                                                                        <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                                                                            <div className="panel-body" >
                                                                                                Aqua G RO Customer Care Number is <strong>8130112493</strong>.
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>


                                                                                </div>
                                                                                <div className="card panel panel-default">
                                                                                    <div className="card-header" id="headingTwo">
                                                                                        <h5 className="mb-0">
                                                                                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                                                                <i className="more-less glyphicon glyphicon-plus">&nbsp;</i> Why RO Service is Needed in {title} ?
                                                                                            </button>
                                                                                        </h5>
                                                                                    </div>
                                                                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                                                                        <div className="panel-body">
                                                                                            Buying a RO water purifier is not enough. You have to maintain it properly to ensure that harmful contaminants don?t pollute your drinking water.
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="card panel panel-default">
                                                                                    <div className="card-header" id="headingThree">
                                                                                        <h5 className="mb-0">
                                                                                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                                                                <i className="more-less glyphicon glyphicon-plus">&nbsp;</i> How Much Time is Required For RO Installation in {title} ?
                                                                                            </button>
                                                                                        </h5>
                                                                                    </div>
                                                                                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                                                                        <div className="panel-body">
                                                                                            We provides RO service in {title} in same day when service is booked. In Some Place we provides services within 90 minutes. No other service provider is as quick as Aqua G RO Services installation service centre.
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="card panel panel-default">
                                                                                    <div className="card-header" id="headingOne">
                                                                                        <h5 className="mb-0">
                                                                                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                                                                                                <i className="more-less glyphicon glyphicon-plus">&nbsp;</i> Why Should I Purchase RO AMC Plans in {title} ?
                                                                                            </button>
                                                                                        </h5>
                                                                                    </div>

                                                                                    <div id="collapseFour" className="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                                                                                        <div className="panel-body">
                                                                                            Your water purifier demands regular repair, maintenance, and service to effective purification, which can be costly. RO AMC plans in {title} offer periodic maintenance and repair service of your water purifier. They provide various kinds of customized AMC plans, thus choose according to your budget and requirement.
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="card panel panel-default">
                                                                                    <div className="card-header" id="headingFive">
                                                                                        <h5 className="mb-0">
                                                                                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
                                                                                                <i className="more-less glyphicon glyphicon-plus">&nbsp;</i> When Should I Go For RO Repair Services in {title} ?
                                                                                            </button>
                                                                                        </h5>
                                                                                    </div>

                                                                                    <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                                                                                        <div className="panel-body">
                                                                                            Your water purifier starts showing some signs for it like odour in purified water, leakage, suddenly stop working and many other. Identify these signs and book RO repair services from Aqua G RO Services. you can book your service by calling on <a href="tel:8130112493">+91-8130112493</a>. You can also register your service / complain through Whatsapp <a href="https://api.whatsapp.com/send?phone=918130112493" target="_blank">+91-8130112493</a> or Visit <a href="www.aqua Groservices.com" target="_blank">www.aqua Groservices.com</a> for more details.
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="card panel panel-default">
                                                                                    <div className="card-header" id="headingSix">
                                                                                        <h5 className="mb-0">
                                                                                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
                                                                                                <i className="more-less glyphicon glyphicon-plus">&nbsp;</i> Why Choose RO Water Purifier service Center in {title} ?
                                                                                            </button>
                                                                                        </h5>
                                                                                    </div>

                                                                                    <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordion">
                                                                                        <div className="panel-body">
                                                                                            RO water purifier service centre in {title} is available for all process such as installation, maintenance, and repairing of water purifier. They also understand customer budget and requirement thus choose Aqua G RO Services at best and affordable market price In PAN India.
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="card panel panel-default">
                                                                                    <div className="card-header" id="headingSeven">
                                                                                        <h5 className="mb-0">
                                                                                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="true" aria-controls="collapseSeven">
                                                                                                <i className="more-less glyphicon glyphicon-plus">&nbsp;</i> Do you charge for inspection and diagnosis of the problem?
                                                                                            </button>
                                                                                        </h5>
                                                                                    </div>

                                                                                    <div id="collapseSeven" className="collapse" aria-labelledby="headingSeven" data-parent="#accordion">
                                                                                        <div className="panel-body">
                                                                                            Yes, we charge Rs 300/- as a visiting charge. However, this price may vary from place to place. And once you ask our service engineer to resolve the issues, this price gets adjusted.
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="card panel panel-default">
                                                                                    <div className="card-header" id="headingEight">
                                                                                        <h5 className="mb-0">
                                                                                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseEight" aria-expanded="true" aria-controls="collapseEight">
                                                                                                <i className="more-less glyphicon glyphicon-plus">&nbsp;</i> Which brands of water purifiers do you repair?
                                                                                            </button>
                                                                                        </h5>
                                                                                    </div>

                                                                                    <div id="collapseEight" className="collapse" aria-labelledby="headingEight" data-parent="#accordion">
                                                                                        <div className="panel-body">
                                                                                            We mainly deal in Kent, Aqua Guard, Aqua Fresh, Eureka Forbes, LivPure, PureIt and various other brands water purifiers. However, our RO water purifier services are available for all brands and kinds of water purifiers. So whenever you need services for your water purifier, do contact us via phone call or SMS.
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>



                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>
                        <section className="AMC_pans__section position-relative pt-0 pb-5" id="amc" style={{ padding: "40px 0", backgroundColor: 'white' }}>
                            <section className="container" style={{ display: 'block' }}>
                                <section className="row amcsdd" id="#amcaccordion">
                                    <div className="widget-title wow fadeInUp text-center mb-1" style={{ width: '100%', position: 'relative' }}>
                                        <h4 className="fonts4em" style={{ fontSize: '30px', fontWeight: '600px' }}>Book AMC Plans </h4>
                                    </div>

                                    <div className="amc__item">
                                        <div className="main_section_sec mt-3 mb-2">
                                            <div className="service_tile_contnt">
                                                <h4>AMC Plan - 1</h4>
                                                <p>Annual maintenance plan for your RO covering 3-4 routine services.</p>
                                            </div>
                                            <div className="service_price">
                                                <i className="fas fa-rupee-sign"></i> 999/-
                                            </div>
                                            <div className="book_now_btions">
                                                <a href="#" data-toggle="modal" data-target="#newModalForWizard">Book Now</a>
                                            </div>
                                            <div className="down_arrow" id="amcheadingOne" >
                                                <button className="nav-toggle collapsed" data-toggle="collapse" data-target="#amccollapseOne" aria-expanded="true" aria-controls="amccollapseOne"><i className="fas fa-chevron-down"></i></button>
                                            </div>
                                            <div id="amccollapseOne" className="cotentss collapse" aria-labelledby="amcheadingOne" data-parent="#amcaccordion">
                                                <div className="pricing-features">
                                                    <div className="feature">Service<span>Include</span> <span className="mnss"><img src="img/location/mark.png" alt="RO Service" loading="lazy" /></span></div>
                                                    <div className="feature">Filters<span>Exclude</span><span className="mnss"><img src="img/location/cross.png" alt="Filter" loading="lazy" /></span></div>
                                                    <div className="feature">Membrane<span>Exclude</span><span className="mnss"><img src="img/location/cross.png" alt="Membrane" loading="lazy" /></span></div>
                                                    <div className="feature">Electric Parts<span>Exclude</span><span className="mnss"><img src="img/location/cross.png" alt="Electric Parts" loading="lazy" /></span></div>
                                                    <div className="feature">Faulty Parts<span>Exclude</span><span className="mnss"><img src="img/location/cross.png" alt="RO Service" loading="lazy" /></span></div>
                                                    <div className="note_for_price"><strong><i className="fa fa-exclamation-circle" aria-hidden="true"></i> Note:</strong> Aqua G RO Services India Spare Parts will be used in AMC Plans.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="amc__item">
                                        <div className="main_section_sec mt-3 mb-2">
                                            <div className="service_tile_contnt">
                                                <h4>AMC Plan - 2</h4>
                                                <p>Annual maintenance plan for RO covering routine services and filters.</p>
                                            </div>
                                            <div className="service_price">
                                                <i className="fas fa-rupee-sign"></i> 2425/-
                                            </div>
                                            <div className="book_now_btions">
                                                <a href="#" data-toggle="modal" data-target="#newModalForWizard">Book Now</a>
                                            </div>
                                            <div className="down_arrow" id="amcheadingTwo">
                                                <button className="nav-toggle collapsed" data-toggle="collapse" data-target="#amccollapseTwo" aria-expanded="true" aria-controls="amccollapseTwo"><i className="fas fa-chevron-down"></i></button>
                                            </div>
                                            <div id="amccollapseTwo" className="cotentss collapse" aria-labelledby="amcheadingTwo" data-parent="#amcaccordion">
                                                <div className="pricing-features">
                                                    <div className="feature">Service<span>Include</span> <span className="mnss"><img src="img/location/mark.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="feature">Filters<span>Include</span><span className="mnss"><img src="img/location/mark.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="feature">Membrane<span>Exclude</span><span className="mnss"><img src="img/location/cross.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="feature">Electric Parts<span>Exclude</span><span className="mnss"><img src="img/location/cross.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="feature">Faulty Parts<span>Exclude</span><span className="mnss"><img src="img/location/cross.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="note_for_price"><strong><i className="fa fa-exclamation-circle" aria-hidden="true"></i> Note:</strong>  Aqua G RO Service parts will be used in AMC Plans.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="amc__item">
                                        <div className="main_section_sec mt-3 mb-2">
                                            <div className="service_tile_contnt">
                                                <h4>AMC Plan - 3</h4>
                                                <p>Annual maintenance plan for your RO covering routine services and electrical parts.</p>
                                            </div>
                                            <div className="service_price">
                                                <i className="fas fa-rupee-sign"></i> 2500/-
                                            </div>
                                            <div className="book_now_btions">
                                                <a href="#" data-toggle="modal" data-target="#newModalForWizard">Book Now</a>
                                            </div>
                                            <div className="down_arrow" id="amcheadingThree">
                                                <button className="nav-toggle collapsed" data-toggle="collapse" data-target="#amccollapseThree" aria-expanded="true" aria-controls="amccollapseThree"><i className="fas fa-chevron-down"></i></button>
                                            </div>
                                            <div id="amccollapseThree" className="cotentss collapse" aria-labelledby="amcheadingThree" data-parent="#amcaccordion">
                                                <div className="pricing-features">
                                                    <div className="feature">Service<span>Include</span> <span className="mnss"><img src="img/location/mark.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="feature">Filters<span>Exclude</span><span className="mnss"><img src="img/location/cross.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="feature">Membrane<span>Exclude</span><span className="mnss"><img src="img/location/cross.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="feature">Electric Parts<span>Include</span><span className="mnss"><img src="img/location/mark.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="feature">Faulty Parts<span>Exclude</span><span className="mnss"><img src="img/location/cross.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="note_for_price"><strong><i className="fa fa-exclamation-circle" aria-hidden="true"></i> Note:</strong> Aqua G RO Services India Spare Parts will be used in AMC Plans.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="amc__item">
                                        <div className="main_section_sec mt-3 mb-2">
                                            <div className="service_tile_contnt">
                                                <h4>AMC Plan - 4</h4>
                                                <p>Annual maintenance plan for your RO covering routine services, filters and membrane.</p>
                                            </div>
                                            <div className="service_price">
                                                <i className="fas fa-rupee-sign"></i> 4000/-
                                            </div>
                                            <div className="book_now_btions">
                                                <a href="#" data-toggle="modal" data-target="#newModalForWizard">Book Now</a>
                                            </div>
                                            <div className="down_arrow" id="amcheadingFour">
                                                <button className="nav-toggle collapsed" data-toggle="collapse" data-target="#amccollapseFour" aria-expanded="true" aria-controls="amccollapseFour"><i className="fas fa-chevron-down"></i></button>
                                            </div>
                                            <div id="amccollapseFour" className="cotentss collapse" aria-labelledby="amcheadingFour" data-parent="#amcaccordion">
                                                <div className="pricing-features">
                                                    <div className="feature">Service<span>Include</span> <span className="mnss"><img src="img/location/mark.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="feature">Filters<span>Include</span><span className="mnss"><img src="img/location/mark.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="feature">Membrane<span>Include</span><span className="mnss"><img src="img/location/mark.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="feature">Electric Parts<span>Exclude</span><span className="mnss"><img src="img/location/cross.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="feature">Faulty Parts<span>Exclude</span><span className="mnss"><img src="img/location/cross.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="note_for_price"><strong><i className="fa fa-exclamation-circle" aria-hidden="true"></i> Note:</strong> Aqua G RO Services India Spare Parts will be used in AMC Plans.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="amc__item">
                                        <div className="main_section_sec mt-3 mb-2">
                                            <div className="service_tile_contnt">
                                                <h4>AMC Plan - 5</h4>
                                                <p>Annual maintenance plan for your RO covering routine services, filters, membrane and electric parts.</p>
                                            </div>
                                            <div className="service_price">
                                                <i className="fas fa-rupee-sign"></i> 5000/-
                                            </div>
                                            <div className="book_now_btions">
                                                <a href="#" data-toggle="modal" data-target="#newModalForWizard">Book Now</a>
                                            </div>
                                            <div className="down_arrow" id="amcheadingFive">
                                                <button className="nav-toggle collapsed" data-toggle="collapse" data-target="#amccollapseFive" aria-expanded="true" aria-controls="amccollapseFive"><i className="fas fa-chevron-down"></i></button>
                                            </div>
                                            <div id="amccollapseFive" className="cotentss collapse" aria-labelledby="amcheadingFive" data-parent="#amcaccordion">
                                                <div className="pricing-features">
                                                    <div className="feature">Service<span>Include</span> <span className="mnss"><img src="img/location/mark.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="feature">Filters<span>Include</span><span className="mnss"><img src="img/location/mark.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="feature">Membrane<span>Include</span><span className="mnss"><img src="img/location/mark.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="feature">Electric Parts<span>Include</span><span className="mnss"><img src="img/location/mark.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="feature">Faulty Parts<span>Exclude</span><span className="mnss"><img src="img/location/cross.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="note_for_price"><strong><i className="fa fa-exclamation-circle" aria-hidden="true"></i> Note:</strong> Aqua G RO Services India Spare Parts will be used in AMC Plans.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="amc__item">
                                        <div className="main_section_sec mt-3 mb-2">
                                            <div className="service_tile_contnt">
                                                <h4>AMC Plan - 6</h4>
                                                <p>Annual maintenance plan for your RO covering routine services, filters, membrane, electric parts and faulty parts.</p>
                                            </div>
                                            <div className="service_price">
                                                <i className="fas fa-rupee-sign"></i> 6000/-
                                            </div>
                                            <div className="book_now_btions">
                                                <a href="#" data-toggle="modal" data-target="#newModalForWizard">Book Now</a>
                                            </div>
                                            <div className="down_arrow" id="amcheadingSix">
                                                <button className="nav-toggle collapsed" data-toggle="collapse" data-target="#amccollapseSix" aria-expanded="true" aria-controls="amccollapseSix"><i className="fas fa-chevron-down"></i></button>
                                            </div>
                                            <div id="amccollapseSix" className="cotentss collapse" aria-labelledby="amcheadingSix" data-parent="#amcaccordion">
                                                <div className="pricing-features">
                                                    <div className="feature">Service<span>Include</span> <span className="mnss"><img src="img/location/mark.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="feature">Filters<span>Include</span><span className="mnss"><img src="img/location/mark.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="feature">Membrane<span>Include</span><span className="mnss"><img src="img/location/mark.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="feature">Electric Parts<span>Include</span><span className="mnss"><img src="img/location/mark.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="feature">Faulty Parts<span>Include</span><span className="mnss"><img src="img/location/mark.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="note_for_price"><strong><i className="fa fa-exclamation-circle" aria-hidden="true"></i> Note:</strong> Aqua G RO Services India Spare Parts will be used in AMC Plans.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </section>
                            </section>
                        </section>
                    </div>
                </div>
            </div>
            
        </>
    )
}