import { useState, useEffect } from "react";
import { Route, Routes, Switch } from 'react-router-dom';
import JsonData from "./data/data.json";
import locdata from "./data/locdata.json";

import SmoothScroll from "smooth-scroll";
import "./App.css";
import { Navigation } from "./components/navigation";
import Home from "./Home";
import { Footer } from "./components/footer";
import Footerdetail from "./components/Footerdetail";

import { Privacypolicy } from "./components/pages/privacypolicy";
import { Delhi } from "./components/location/Delhi";
import DelhiPopularcity from "./components/location/nearme/Delhinearme";



export const scroll = new SmoothScroll('a[href*="/"]', {
  speed: 1000,
  speedAsDuration: true,
});


const App = () => {
  

  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
    
      <div className='social'>
        {/*
        <div>
          <a className="whatsapp" href="https://api.whatsapp.com/send?phone=918130112493" target="_blank">
            <i className="fa fa-whatsapp"></i>
          </a>
        </div>  
        */}
        <div className="pop">
          <a className="phonescreen" href="tel:8130112493">
            <img style={{ border: "0" }} src="img/banners/quickenquiry.png" alt="callus@8130112493 " />
          </a>
        </div>
      </div>
      <Navigation />

      <Routes>
        <Route exact path="/" element={<Home />} />
        
        <Route path="/privacy-policy" element={<Privacypolicy />} />

        <Route path="/ro-service-Delhi" element={<Delhi title={locdata.Delhi.title} number={locdata.Delhi.number}/>}/>
        <Route path="/ro-service-NewDelhi" element={<Delhi title={locdata.NewDelhi.title} number={locdata.NewDelhi.number}/>}/>
        <Route path="/ro-service-Noida" element={<Delhi title={locdata.Noida.title} number={locdata.Noida.number}/>}/>
        <Route path="/ro-service-Greater-Noida" element={<Delhi title={locdata.GreaterNoida.title} number={locdata.GreaterNoida.number}/>}/>
        <Route path="/ro-service-Ghaziabad" element={<Delhi title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number}/>}/>
        <Route path="/ro-service-Gurugram" element={<Delhi title={locdata.Gurugram.title} number={locdata.Gurugram.number}/>}/>
        <Route path="/ro-service-Gurgaon" element={<Delhi title={locdata.Gurgaon.title} number={locdata.Gurgaon.number}/>}/>
        <Route path="/ro-service-Faridabad" element={<Delhi title={locdata.Faridabad.title} number={locdata.Faridabad.number}/>}/>
          
        <Route path="/water-purifier-service-Delhi" element={<Delhi title={locdata.Delhi.title} number={locdata.Delhi.number}/>}/>
        <Route path="/water-purifier-service-NewDelhi" element={<Delhi title={locdata.NewDelhi.title} number={locdata.NewDelhi.number}/>}/>
        <Route path="/water-purifier-service-Noida" element={<Delhi title={locdata.Noida.title} number={locdata.Noida.number}/>}/>
        <Route path="/water-purifier-service-Greater-Noida" element={<Delhi title={locdata.GreaterNoida.title} number={locdata.GreaterNoida.number}/>}/>
        <Route path="/water-purifier-service-Ghaziabad" element={<Delhi title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number}/>}/>
        <Route path="/water-purifier-service-Gurugram" element={<Delhi title={locdata.Gurugram.title} number={locdata.Gurugram.number}/>}/>
        <Route path="/water-purifier-service-Gurgaon" element={<Delhi title={locdata.Gurgaon.title} number={locdata.Gurgaon.number}/>}/>
        <Route path="/water-purifier-service-Faridabad" element={<Delhi title={locdata.Faridabad.title} number={locdata.Faridabad.number}/>}/>
         
        <Route path="/ro-service-nearby-Delhi" element={<Delhi title={locdata.Delhi.title} number={locdata.Delhi.number}/>}/>
        <Route path="/ro-service-nearby-NewDelhi" element={<Delhi title={locdata.NewDelhi.title} number={locdata.NewDelhi.number}/>}/>
        <Route path="/ro-service-nearby-Noida" element={<Delhi title={locdata.Noida.title} number={locdata.Noida.number}/>}/>
        <Route path="/ro-service-nearby-Greater-Noida" element={<Delhi title={locdata.GreaterNoida.title} number={locdata.GreaterNoida.number}/>}/>
        <Route path="/ro-service-nearby-Ghaziabad" element={<Delhi title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number}/>}/>
        <Route path="/ro-service-nearby-Gurugram" element={<Delhi title={locdata.Gurugram.title} number={locdata.Gurugram.number}/>}/>
        <Route path="/ro-service-nearby-Gurgaon" element={<Delhi title={locdata.Gurgaon.title} number={locdata.Gurgaon.number}/>}/>
        <Route path="/ro-service-nearby-Faridabad" element={<Delhi title={locdata.Faridabad.title} number={locdata.Faridabad.number}/>}/>
         

        </Routes>
      <DelhiPopularcity />
      <Footerdetail data={landingPageData.Contact} />
      <Footer data={landingPageData.Footer} />
    </div>

  );
};


export default App;

