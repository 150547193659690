import React, { useState } from 'react'

const DelhiPopularcity = (props) => {
    return (
        <>
            <div id='footerdetail' >
                <div id='callus'>
                    <div className="container">
                        <div className="row">

                            <div className="col-md-12 col-lg-12">

                                <div className="popular-city" style={{ display: 'flex' }}>
                                    <div className="footer-widget footer-left-widgetcties-pop popular-city-footers">
                                        <div className="section-heading">
                                            <h3>RO Service Near You</h3>
                                            <span className="animate-border border-black"></span>
                                        </div>
                                        <ul>
                                            <li>  <a href="/ro-service-Delhi">Delhi</a>	</li>
                                            <li>  <a href="/ro-service-NewDelhi">New Delhi</a>	</li>
                                            <li>  <a href="/ro-service-Noida">Noida</a>	</li>
                                            <li>  <a href="/ro-service-Greater-Noida">Greater-Noida</a>	</li>
                                            <li>  <a href="/ro-service-Ghaziabad">Ghaziabad</a>	</li>
                                            <li>  <a href="/ro-service-Gurugram">Gurugram</a>	</li>
                                            <li>  <a href="/ro-service-Gurgaon">Gurgaon</a>	</li>
                                            <li>  <a href="/ro-service-Faridabad">Faridabad</a>	</li>
                                            
                                            
                                        </ul>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default DelhiPopularcity;